<template>
  <Page full>
    <Grid
      type="dimension"
      title="Dimensions"
      :multiple="true"
      :visible="[
        'dimension_preview',
        'company_id',
        // 'company_name',
        'dimension_measure_type',
        'dimension_desc'
      ]"
      :order="[['company_id', 'asc']]"
      :isMain="true"
    />
  </Page>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  name: 'SuperDimensionsPage',
  components: {
    Grid
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
